// CASE STUDY
import heroBgImage from '../assets/images/case-study-hero-desk.webp';
import { ServiceProps } from '@/components/shared/Services/Service/Service';

// HISTORY SLIDER
import { HistorySlide } from '../sections/HistorySlider/HistorySlider';

// ABOUT
import AboutHeroBgImg from '../assets/images/about-hero-background.webp';

import { MenuItem } from '@/components/shared/Drawer/Menu/Menu';

export type CaseStudyProps = {
  id: string;
  name: JSX.Element | string;
  shortTitle?: string;
  field: string;
  description: string;
  image: string;
  imgAlt: string;
  hero: {
    title: string;
    heroDescriptionText: string;
    bgImg: string;
    projectSection: {
      delivery: { header: string; p: string };
      duration: { header: string; p: string };
    };
  };
  link: string;
  externalLink?: string;
  pageDescription: string;
  sliderImage: string;
};

export const CaseStudyData: CaseStudyProps[] = [
  {
    id: 'projectx',
    description:
      'We partnered with leading venture capital firm to build a data-driven tech ecosystem, integrating GenAI and automation to streamline processes, enhance decision-making, and reinforce leadership.',
    field: 'Custom Solutions',
    link: '/case-studies/projectx',
    name: 'Project X',
    image: '/images/case-studies/projectx/x-header.webp',
    sliderImage: '/images/case-studies/projectx/x-header.webp',
    imgAlt: '',
    externalLink: '',
    hero: {
      title: 'Project X',
      heroDescriptionText:
        "Discover how Loop3's dedicated remote team partnered with a leading venture capital firm with over 1.5 Billion under management (we can't say who, but trust us—they're big) to build a cutting-edge, data-driven technology ecosystem. By integrating GenAI and advanced automation tools, our team streamlined their processes, empowering them to make smarter decisions and solidify their leadership in the industry—all while keeping things under wraps.",
      bgImg: '/images/case-studies/projectx/x-header.webp',
      projectSection: {
        delivery: {
          header: 'Delivery',
          p: 'Custom Solutions',
        },
        duration: {
          header: 'Duration',
          p: 'Ongoing',
        },
      },
    },
    pageDescription: 'CASE STUDY',
  },
  {
    id: 'pem',
    description:
      "Loop3 collaborated with 'Por el Mar' to develop a custom web application, transforming their financial management from error-prone spreadsheets to an efficient system, enabling them to focus on ocean conservation.",
    field: 'Web Development',
    link: '/case-studies/pem',
    name: 'Por El Mar',
    image: '/images/case-studies/pem/pem-banner.webp',
    sliderImage: '/images/case-studies/pem/pem-banner.webp',
    imgAlt: 'Por El Mar',
    externalLink: '',
    hero: {
      title: 'Por El Mar',
      heroDescriptionText:
        "Discover how Loop3 partnered with 'Por el Mar', a leading marine conservation organization, to revolutionize their financial management processes. By creating a custom web application, we helped them shift from time-consuming, error-prone spreadsheets to a streamlined, efficient system, allowing them to focus more on their mission of protecting our oceans.",
      bgImg: '/images/case-studies/pem/pem-banner.webp',
      projectSection: {
        delivery: {
          header: 'Delivery',
          p: 'Web Application',
        },
        duration: {
          header: 'Duration',
          p: '16 weeks',
        },
      },
    },
    pageDescription: 'CASE STUDY',
  },
  {
    id: 'unifine',
    description:
      'Loop3 partnered with White Canvas to create a custom QA automation tool, replacing manual testing, improving bug detection, and boosting project quality and efficiency.',
    field: 'Web Application',
    link: '/case-studies/unifine',
    name: 'Unifine',
    image: '/images/case-studies/unifine/unifine-header.webp',
    sliderImage: '/images/case-studies/unifine/unifine-header.webp',
    imgAlt: '',
    externalLink: '',
    hero: {
      title: 'Unifine',
      heroDescriptionText:
        'Discover how Loop3 collaborated with White Canvas to develop a powerful QA automation tool that transformed their testing process. By creating an intuitive, custom solution, we helped them move away from time-consuming manual testing, enabling them to catch bugs early and enhance the quality of their projects, all while improving overall efficiency.',
      bgImg: '/images/case-studies/unifine/unifine-header.webp',
      projectSection: {
        delivery: {
          header: 'Delivery',
          p: 'Web Application',
        },
        duration: {
          header: 'Duration',
          p: '16 weeks',
        },
      },
    },
    pageDescription: 'CASE STUDY',
  },
  {
    id: 'nytimes',
    description:
      'We developed a brand new site for The New York Times - Media Kit, a place where marketers can look for the different advertising options to publish on The New York Times',
    field: 'UX/UI Design - Web Dev.',
    link: '/case-studies/nytimes',
    name: 'NY Times\nMedia Kit',
    shortTitle: 'NY Times',
    image: '/images/case-studies/nytimes/cover.2.webp',
    sliderImage: '/images/case-studies/nytimes/slider.webp',
    imgAlt: 'NY Times',
    externalLink: 'https://advertising.nytimes.com/',
    hero: {
      title: 'NYT Media Kit',
      heroDescriptionText:
        'We developed a brand new site for The New York Times - Media Kit, a place where marketers can look for the different advertising options to publish on The New York Times',
      bgImg: heroBgImage,
      projectSection: {
        delivery: {
          header: 'Delivery',
          p: 'UX/UI Design - Web Dev.',
        },
        duration: {
          header: 'Duration',
          p: '10 weeks',
        },
      },
    },
    pageDescription: 'CASE STUDY',
  },
  {
    id: 'get-real',
    description:
      'The creators of the iconic "got milk?" brand, the California Milk Processor Board (CMPF) wants everyone to get real and is launching the Get Real campaign to promote the benefits of being real in all aspects of life, including drinking real milk.',
    field: 'Web & WordPress Dev.',
    link: '/case-studies/gotmilk',
    name: 'Get Real',
    image: '/images/case-studies/getreal/cover.webp',
    sliderImage: '/images/case-studies/getreal/slider.webp',
    imgAlt: 'Get Real',
    externalLink: 'https://get-real-california.gotmilk.com/',
    hero: {
      title: 'Get real',
      heroDescriptionText:
        'The creators of the iconic "got milk?" brand, the California Milk Processor Board (CMPF) wants everyone to get real and is launching the Get Real campaign to promote the benefits of being real in all aspects of life, including drinking real milk.',
      bgImg: heroBgImage,
      projectSection: {
        delivery: {
          header: 'Delivery',
          p: 'Web & WordPress Development',
        },
        duration: {
          header: 'Duration',
          p: '8 weeks',
        },
      },
    },
    pageDescription: 'CASE STUDY',
  },
  {
    id: 'ahora-vuelvo-mama',
    description:
      'Ahora Vuelvo Mama is a Spanish company whose main objective is to revolutionize the concept of one-day travels. During 2019 they reached more than 8000 passengers.',
    field: 'UX/UI Design - Web Dev.',
    link: '/case-studies/ahoravuelvomama',
    name: 'Ahora Vuelvo\nMama',
    image: '/images/case-studies/ahoravuelvomama/main-hero.webp',
    sliderImage: '/images/case-studies/ahoravuelvomama/slider.webp',
    imgAlt: 'Ahora Vuelvo Mama',
    externalLink: 'https://ahoravuelvomama.es',
    hero: {
      heroDescriptionText:
        'When the client came to us with the idea of creating a site to disrupt the one-day-travel booking system we were right on!',
      title: 'AVM App',
      bgImg: heroBgImage,
      projectSection: {
        delivery: {
          header: 'Delivery',
          p: 'UX/UI Design & Web Development',
        },
        duration: {
          header: 'Duration',
          p: '16 weeks',
        },
      },
    },
    pageDescription: 'CASE STUDY',
  },
  {
    id: 'dr-b',
    description:
      'Some platforms not always regard UX as a priority. In this project we modifyed an outdated UI, to simplify it, make it more readable, and usable. Find out how!',
    field: 'Web Development',
    link: '/case-studies/drbubba',
    name: 'Dr. Bubba',
    image: '/images/case-studies/drbubba/main-hero.new.webp',
    sliderImage: '/images/case-studies/drbubba/slider.webp',
    imgAlt: 'Dr. Bubba',
    externalLink: 'https://www.hidrb.com',
    hero: {
      title: 'Dr. Bubba',
      heroDescriptionText:
        'Some platforms not always regard UX as a priority. In this project we modifyed an outdated UI, to simplify it, make it more readable, and usable. Find out how!',
      bgImg: heroBgImage,
      projectSection: {
        delivery: {
          header: 'Delivery',
          p: 'Web Development',
        },
        duration: {
          header: 'Duration',
          p: '6 weeks',
        },
      },
    },
    pageDescription: 'CASE STUDY',
  },
  {
    id: 'time-2-talk',
    description:
      'Can your website be a tool to help you fulfill your business goals? In this project, we will show you how a website can not only look great but also collaborate in achieving all sorts of business goals.',
    field: 'Web Design & WordPress Dev.',
    link: '/case-studies/time2talk',
    name: 'Time2Talk',
    image: '/images/case-studies/time2talk/main-hero.webp',
    sliderImage: '/images/case-studies/time2talk/slider.webp',
    imgAlt: 'Time2Talk',
    externalLink: '',
    hero: {
      title: 'Time2Talk',
      heroDescriptionText:
        "If someone comes to you with an app that will help people learn your native language wouldn't you help them? Keep reading to learn how we helped Time2Talk meet its goals!",
      bgImg: heroBgImage,
      projectSection: {
        delivery: {
          header: 'Delivery',
          p: 'Web design & WordPress Development',
        },
        duration: {
          header: 'Duration',
          p: '16 weeks',
        },
      },
    },
    pageDescription: 'CASE STUDY',
  },
];

export const CASE_STUDIES_HERO = {
  title: 'Case\nStudies',
  heroDescriptionText:
    "We let our work speaks for itself, I'll bet you'd be the next case study on our website.",
  bgImg: heroBgImage,
};

export const MENU_ITEMS: MenuItem[] = [
  {
    id: 0,
    label: 'Services',
    url: '/#services',
  },
  {
    id: 1,
    label: 'Case Studies',
    url: '/case-studies/',
  },
  {
    id: 2,
    label: 'Blog',
    url: '/blog',
  },
  {
    id: 3,
    label: 'About',
    url: '/about/',
  },
  {
    id: 4,
    label: 'Contact',
    url: '/#contact-us',
  },
];

export const SERVICES: { headline: string; subheadline: string; services: ServiceProps[] } = {
  headline: `Our Services<br />& Solutions<br />&#92;`,
  subheadline:
    "We empower Venture Capitals to streamline their data, tools, and workflows, delivering tailored solutions that drive smarter decisions and greater efficiency. Here's how we do it:",
  services: [
    {
      id: 1,
      name: 'Tool Integration \n & Optimization',
      descriptions: [
        'Seamlessly connect the tools you already use—CRMs, financial systems, and proprietary platforms—into a unified ecosystem. We eliminate silos, ensuring your data flows effortlessly and delivers actionable insights right when you need them.',
      ],
      subdescription: 'Tools We Love',
      products: [
        { name: 'AFFINITY' },
        { name: 'PITCHBOOK' },
        { name: 'HARMONIC' },
        { name: 'SLACK' },
        { name: 'CHATGPT & CLAUDE' },
        { name: 'OTHER' },
      ],
      position: 'Left',
    },
    {
      id: 2,
      name: 'Data \n Visualization \n & Dashboards',
      descriptions: [
        'Transform raw data into meaningful, real-time insights that drive action. We specialize in designing intuitive dashboards that not only allow you to track and analyze the metrics that matter most to your portfolio and pipeline but also empower you to make data-driven decisions with confidence. ',
        'Our custom-built dashboards consolidate information from various sources into a single, cohesive view, offering a comprehensive understanding of your business performance. With these visual tools, you can easily identify trends, measure success, and take proactive steps to optimize outcomes—all in real-time.',
      ],
      position: 'Right',
    },
    {
      id: 3,
      name: 'Custom Software \n Solutions',
      descriptions: [
        'When your tools need more, we build it. From expanding functionalities to creating entirely custom applications, we tailor solutions to meet your unique challenges and scale alongside your business.',
      ],
      products: [
        { name: 'Custom Platforms' },
        { name: 'Web & Mobile App Development' },
        { name: 'Research & Strategy' },
        { name: 'Custom Ai Agents' },
      ],
      position: 'Left',
    },
    {
      id: 4,
      name: 'Workflow \n Automation',
      descriptions: [
        'Save time and reduce errors by automating repetitive processes across your systems. We design and implement workflows that let your team focus on strategy, not busywork.',
      ],
      products: [
        { name: 'Lp Reporting And Notification Workflows' },
        { name: 'Deal Flow Pipeline Automation' },
        { name: 'Ai-Powered Email And Calendar automation' },
        { name: 'Performance Altering & Notifications' },
      ],
      position: 'Right',
    },
    {
      id: 5,
      name: 'Ongoing Support \n & Scalability',
      descriptions: [
        "Your business needs are constantly evolving, and your tools should too. As your company grows, it's essential that your systems adapt to new challenges. We provide ongoing support to ensure your tools stay flexible and scalable, meeting your changing requirements as you expand and refine your operations.",
        'Our support goes beyond maintenance—it focuses on optimizing performance, ensuring security, and aligning systems with your long-term goals. We proactively monitor and update your infrastructure to avoid roadblocks, reduce downtime, and keep your technology aligned with your business vision. With Loop3, your systems grow and improve with your business.',
      ],
      position: 'Left',
    },
  ],
};

export const HISTORYSLIDES: HistorySlide[] = [
  {
    id: 1,
    headline: '“We all have a past, a present, and a future.”',
    body: "Our journey shapes who we are, our actions define where we stand, and our vision drives where we're headed. At Loop3, we're committed to building solutions that connect your past, optimize your present, and empower your future",
  },
  {
    id: 2,
    headline: 'Our Past',
    body: "We started as a small team of tech enthusiasts with a passion for solving complex problems. From late nights debugging code to crafting innovative solutions, we've grown into a trusted partner for businesses looking to optimize their tools and workflows. Along the way, we found our niche: empowering Venture Capitals to unlock the true potential of their data and systems.",
  },
  {
    id: 3,
    headline: 'Our Present',
    body: "Today, we work alongside some of the brightest minds in the VC world. We're the ones who bridge the gap between ambition and execution, integrating tools, automating workflows, and building custom solutions that make data actionable. Our mission is clear: to help VCs make smarter, faster decisions by removing the friction in their systems.",
  },
  {
    id: 4,
    headline: 'Our Future',
    body: "The future we envision is seamless. It's a world where VCs have real-time access to the insights that matter, where disconnected systems are a thing of the past, and where every decision is backed by perfectly flowing data. With each project, we're building that future—one integration, one solution, one success story at a time.",
  },
];

export const ABOUTPAGE = {
  title: 'About Us',
  heroDescriptionText:
    "We are the ones you call when you need someone on your corner, a team of people that will tell you what they think and not always what you want to hear. We pride ourselves on challenging your ideas and arriving at what's best for your business while doing so, a true partner that will leverage technology to skyrocket your business.",
  imgSrc: '/images/about/main-hero.webp',
  imgAlt: 'image',
  bgImg: AboutHeroBgImg,
  phraseText: 'Perfect does not exist, but we strive to be as close as possible to that concept.',
  phraseDescriptionText:
    'As our name implies, iteration over our work even after is done is what makes us grow, learn and improve so that after each project we are closer to that goal, perfection.',
};

export const SOCIAL_MEDIA_LINKS = {
  linkedIn: 'https://www.linkedin.com/company/loop3studio/',
};
